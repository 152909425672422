<template>
  <!-- 商品升级/扩容-->

  <el-row class="page">
    <el-col :span="24" style="height: 42px; background: #2D3142;" :sm="0">
      <div class="header-title">订单{{ title }}</div>
    </el-col>
    <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
      <my-membership></my-membership>
    </el-col>
    <el-col :span="24" :sm="0">
      <el-col :span="24" class="mobile-instance">
        <el-col :span="5" class="mobile-product_img">
          <img :src="goodsInfo.productInfo.saasProductLogo" style="width: 100%;height: 100%">
        </el-col>
        <el-col class="mobile-product-name" :span="24">{{ goodsInfo.productInfo.saasProductName }}</el-col>
        <el-col class="mobile-group-but" :span="16">
          <el-button class="mobile-btn" @click="goPage('goodsRenewal', false)">续费</el-button>
          <el-button class="mobile-btn" @click="goPage('goodsExpansion', true)">扩容</el-button>
          <el-button class="mobile-btn" @click="goPage('goodsExpansion', false)">升级</el-button>
        </el-col>
      </el-col>
      <el-col :span="24" class="mobile-goods-describe">
        <el-col :span="24">
          <el-col :span="4" class="mobile-product-tile">产品名称</el-col>
          <el-col :span="15" class="mobile-product-value">{{ goodsInfo.productInfo.saasProductName }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="4" class="mobile-product-tile">当前规格</el-col>
          <el-col :span="15" class="mobile-product-value"> {{
              goodsInfo.userNum == null || goodsInfo.userNum == 0 ? 0 : goodsInfo.userNum
            }}用户{{
              goodsInfo.capacity == null || goodsInfo.capacity == 0 ? '0G' : (goodsInfo.capacity >= 1024 ? ((goodsInfo.capacity / 1024).toFixed(2) + 'T') : (goodsInfo.capacity + 'G'))
            }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="4" class="mobile-product-tile">产品ID</el-col>
          <el-col :span="15" class="mobile-product-value">{{ goodsInfo.productInfo.saasProductId }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="4" class="mobile-product-tile">到期时间</el-col>
          <el-col :span="15" class="mobile-product-value">{{ goodsInfo.orderInfo.orderEndTime }}</el-col>
        </el-col>
      </el-col>
      <el-col :span="24" class="mobile-goods-spe">
        <el-col :span="24">
          <el-col>
            <el-col :span="4" class="mobile-product-tile1">商品规格</el-col>
            <el-col :span="24" class="order-item">
              <el-button
                  :class="{'spaces-item': idx != spacesIndex, 'item-hover': idx == spacesIndex}"
                  v-for="(item,idx) in spacesList"
                  :key="idx"
                  @click="chooseSpaces(item, idx)"
              >
                {{ item.productSpecsName }}
              </el-button>
              <span v-if="spacesList.length == 0">暂无可选规格</span>
              <!-- 自定义规格-->
              <div class="other-spaces" v-if="ifOther">
                <div style="margin-bottom: 10px;" v-if="ifExpansion == 'false'">
                  用户：
                  <el-input-number v-model="inputUserNum" :min="basicUser" :step="basicUser" step-strictly
                                   @change="calculateSpacesPrice"></el-input-number>
                </div>
                <div>
                  容量：
                  <el-input-number v-model="inputCapacity" :min="basicCapacity" :step="basicCapacity" step-strictly
                                   @change="calculateSpacesPrice"></el-input-number>
                  <el-select style="width: 60px;margin-left: 5px" v-model="capacityUnit" @change="inputUnitChange">
                    <el-option label="G" value="G">GB</el-option>
                    <el-option label="T" value="T">TB</el-option>
                  </el-select>
                </div>
              </div>
            </el-col>
            <el-col :span="4" class="mobile-product-tile1" v-if="ifExpansion == 'false'">计费模式</el-col>
            <el-col :span="24" class="order-item" v-if="ifExpansion == 'false'">
              <el-button
                  :class="{'spaces-item': idx != modeIndex, 'item-hover': idx == modeIndex}"
                  v-for="(item,idx) in modeList"
                  :key="idx"
                  @click="chooseMode(item, idx)"
              >
                <span v-if="item.billingMode != 3 && item.billingMode == 1">包月</span>
                <span v-if="item.billingMode != 3 && item.billingMode == 2">包年</span>
              </el-button>
              <span v-if="modeList.length == 0">暂无可选计费模式</span>
            </el-col>
            <el-col :span="4" class="mobile-product-tile1" v-if="ifExpansion == 'false'">购买时长</el-col>
            <el-col :span="24" class="order-item" v-if="ifExpansion == 'false'">
              <el-input-number :disabled="upgradeDisabled" @change="numberChange" :min="minTime" :max="maxTime"
                               v-model="upgradeForm.buyNum"></el-input-number>
              <span>（{{ unit }}）</span>
            </el-col>
            <!-- 立即购买悬浮 -->
            <el-col class="down-mobile" :sm="0" :span="24">
              <el-col style="background: #FFFFFF;  height: 52px;" :span="24">
                <el-col class="down-count" :span="2">
                  总计
                </el-col>
                <el-col class="down-￥" :span="1">
                  ￥
                </el-col>
                <el-col class="down-buy-price" :span="3">
                  {{ upgradeForm.sumPrice }}
                </el-col>
                <el-col style="margin: 9px -29px -9px 162px;" :span="1">
                  <el-button class="down-buy-button" @click="upgrade1 = true" :disabled="upgradeDisabled">立即{{ title }}</el-button>
                </el-col>
              </el-col>
            </el-col>
<!--            <div class="order-item">-->
<!--              <span>总计：</span>-->
<!--              <span class="order-price">￥{{ upgradeForm.sumPrice }}</span>-->
<!--              <span class="price-discount" v-if="discountPrice > 0">&emsp;原套餐剩余天数已抵扣{{ discountPrice }}元</span>-->
<!--            </div>-->
<!--            <div class="order-item">-->
<!--              <button class="upgrade-button" @click="upgrade" :disabled="upgradeDisabled">立即{{ title }}</button>-->
<!--            </div>-->
          </el-col>
        </el-col>
      </el-col>
    </el-col>
    <el-col :xs="0">
      <div class="upgrade">
        <div class="upgrade-title">订单{{ title }}</div>
        <div class="upgrade-content">
          <div class="content-left">
            <div><img class="goods-img" :src="goodsInfo.productInfo.saasProductLogo"></div>
            <div class="text-center goods-title">{{ goodsInfo.productInfo.saasProductName }}</div>
            <div class="text-center button-list">
              <button class="goods-button" @click="goPage('goodsRenewal', false)">续费</button>
              <button :class="{'goods-button':true, 'item-hover': ifExpansion == 'true'}"
                      @click="goPage('goodsExpansion', true)">扩容
              </button>
              <button :class="{'goods-button':true, 'item-hover': ifExpansion != 'true'}"
                      @click="goPage('goodsExpansion', false)">升级
              </button>
            </div>
          </div>
          <div class="content-right">
            <div class="info-list">
              <div class="info-item">产品名称：{{ goodsInfo.productInfo.saasProductName }}</div>
              <!--                        <div class="info-item">规格名称：{{ goodsInfo.spacesInfo.productSpecsName }}</div>-->
              <div class="info-item">
                当前规格：
                {{
                  goodsInfo.userNum == null || goodsInfo.userNum == 0 ? 0 : goodsInfo.userNum
                }}用户{{
                  goodsInfo.capacity == null || goodsInfo.capacity == 0 ? '0G' : (goodsInfo.capacity >= 1024 ? ((goodsInfo.capacity / 1024).toFixed(2) + 'T') : (goodsInfo.capacity + 'G'))
                }}
              </div>
              <div class="info-item">&emsp;产品ID：{{ goodsInfo.productInfo.saasProductId }}</div>
              <div class="info-item">
                到期时间：{{ goodsInfo.orderInfo.orderEndTime }}
              </div>
            </div>
            <div>
              <div class="order-item">
                <span>商品规格：</span>
                <el-button
                    :class="{'spaces-item': idx != spacesIndex, 'item-hover': idx == spacesIndex}"
                    v-for="(item,idx) in spacesList"
                    :key="idx"
                    @click="chooseSpaces(item, idx)"
                >
                  {{ item.productSpecsName }}
                </el-button>
                <span v-if="spacesList.length == 0">暂无可选规格</span>
                <!-- 自定义规格-->
                <div class="other-spaces" v-if="ifOther">
                  <div style="margin-bottom: 10px;" v-if="ifExpansion == 'false'">
                    用户：
                    <el-input-number v-model="inputUserNum" :min="basicUser" :step="basicUser" step-strictly
                                     @change="calculateSpacesPrice"></el-input-number>
                  </div>
                  <div>
                    容量：
                    <el-input-number v-model="inputCapacity" :min="basicCapacity" :step="basicCapacity" step-strictly
                                     @change="calculateSpacesPrice"></el-input-number>
                    <el-select style="width: 60px;margin-left: 5px" v-model="capacityUnit" @change="inputUnitChange">
                      <el-option label="G" value="G">GB</el-option>
                      <el-option label="T" value="T">TB</el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="order-item" v-if="ifExpansion == 'false'">
                <span>计费模式：</span>
                <el-button
                    :class="{'spaces-item': idx != modeIndex, 'item-hover': idx == modeIndex}"
                    v-for="(item,idx) in modeList"
                    :key="idx"
                    @click="chooseMode(item, idx)"
                >
                  <span v-if="item.billingMode != 3 && item.billingMode == 1">包月</span>
                  <span v-if="item.billingMode != 3 && item.billingMode == 2">包年</span>
                </el-button>
                <span v-if="modeList.length == 0">暂无可选计费模式</span>
              </div>
              <div class="order-item" v-if="ifExpansion == 'false'">
                <span>购买时长：</span>
                <el-input-number :disabled="upgradeDisabled" @change="numberChange" :min="minTime" :max="maxTime"
                                 v-model="upgradeForm.buyNum"></el-input-number>
                <span>（{{ unit }}）</span>
              </div>
              <div class="order-item">
                <span>总计：</span>
                <span class="order-price">￥{{ upgradeForm.sumPrice }}</span>
                <span class="price-discount" v-if="discountPrice > 0">&emsp;原套餐剩余天数已抵扣{{ discountPrice }}元</span>
              </div>
              <div class="order-item">
                <button class="upgrade-button" @click="upgrade" :disabled="upgradeDisabled">立即{{ title }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
    <el-col class="product-pay" :xs="0">
      <el-dialog title="订单支付" :visible.sync="payDialog" class="pay-dialog" top="30vh" :close-on-click-modal="false">
          <el-col class="pay-type">
              <!--          <el-col class="pay-wechat active" :span="5">-->
              <!--            <i class="el-icon-wechat pay-icon"></i>-->
              <!--            <el-col :span="18" class="pay-title">微信扫码支付</el-col>-->
              <!--          </el-col>-->
              <el-col class="pay-ali " style="text-align: center">
                  <i class="el-icon-ali pay-icon"></i><span class="active" style="color: black">支付宝扫码支付</span>
              </el-col>
          </el-col>
          <el-col class="qr-code" id="qrcode">
              <img :src="qrCodeImg"/>
          </el-col>

        <el-col class="price">
          <span class="symbol">￥</span>
          <span class="amount">{{ upgradeForm.sumPrice }}</span>
        </el-col>
      </el-dialog>

      <el-dialog title="支付成功" :visible.sync="paySuccessDialog" class="pay-success-dialog" top="30vh"
                 :close-on-click-modal="false">
        <el-col class="price" style="color: green">
          <span>支付成功，即将跳转至订单详情，{{ secondNum }}s</span>
        </el-col>
        <el-col class="price">
          <el-button type="text" style="color: green;" @click="goOrderInfo()">立即跳转>></el-button>
        </el-col>
      </el-dialog>
    </el-col>
  </el-row>
</template>

<script>
import MembershipBar from '../common/MenberShipBar'
import api from '@/api/orderApi.js'
import payApi from '@/api/payApi.js'

export default {
  components: {
    'my-membership': MembershipBar
  }
  , name: "goodsUpgrade"
  , data() {
    return {
      userInfo: {}
      , instanceId: ''
      , goodsInfo: {
            productInfo: {},
            orderInfo: {}
        }  //商品详情
      , spacesList: [] //规格列表
      , spacesIndex: 0
      , modeList: []   //计费模式列表
      , modeIndex: 0
      , minTime: 1 //可选时间的最小数
      , maxTime: 10    //可选时间最大数
      , unit: '月'  //单位名称
      , unitPrice: 100    //单价
      , upgradeForm: {
        packageSpacesId: '' //规格
        , billingModeId: ''  //计费模式
        , buyNum: 1  //购买数量
        , newEndTime: '' //新到期时间
        , sumPrice: 0    //总价
      }
      , remainMonth: 0 //间隔月份
      , discountPrice: 0   //优惠价格
      , payDialog: false
      , qrCodeImg: ''
      , wsUrl: payApi.socketUrl
      , orderNo: ''
      , ifExpansion: false
      , title: '升级'
      , upgradeDisabled: false,

      inputUserNum: 50,
      inputCapacity: 50,
      capacityUnit: 'T'
      , otherSpaces: {
        packageSpecsId: 'other'
        , productSpecsName: '自定义规格'
        , userNum: 50   //用户量
        , capacity: 50 //容量
        , billingModes: [
          {billingModeId: 'month', billingMode: 1, purchaseAmount: 0},
          {billingModeId: 'year', billingMode: 2, purchaseAmount: 0},
        ]
      },
      basicUser: 50,  //基础用户量，控制客户的输入
      basicCapacity: 50,   //基础容量
      //用户和容量的基数与价格，用于计算价格
      basic: {
        capacity: 0
        , capacityPrice: 0
        , user: 0
        , userPrice: 0
      }
      , ifOther: false //是否使用其他规格（自定义）
      , durationMax: 0
      , billingMode: 1
      , paySuccessDialog: false
      , secondNum: 5
    }
  }
  , created() {
    this.instanceId = sessionStorage.getItem("instanceId");
    this.ifExpansion = sessionStorage.getItem("ifExpansion");
    if (this.ifExpansion == 'true') {
      this.title = '扩容';
    }
    this.getInstanceInfo(this.instanceId, this.ifExpansion);
  }
  , mounted() {
  }
  , destroyed() {
    this.websocketOnClose();
  }
  , methods: {
    upgrade() {
      let obj = this;
      //计算到期时间实际需要延长的月份
      let month = obj.upgradeForm.buyNum;
      if (obj.billingMode == 2) {
        month = month * 12;
      }
      month = month - obj.remainMonth;
      console.log('remainMonth', obj.remainMonth);
      console.log('actualMonth', month);
      if (obj.upgradeForm.sumPrice < 0) {
        //如果小于0，则提示不能购买，要把钱抵消完
        return this.$message.warning("抱歉，上一笔订单的剩余金额将不会遗留，请追加购买数量");
      }
      let title = '是否确认' + obj.title + '？';

      this.$confirm(title, '确认订单信息').then(() => {
        let userInfo = obj.$store.state.userInfo.userInfo;
        api.generatorOtherOrder({
          saasUserID: userInfo.saasUserId
          , saasCompanyId: userInfo.saasCompanyId
          , saasGoodsId: obj.goodsInfo.productInfo.saasProductId
          , orderType: obj.ifExpansion == 'true' ? 4 : 3
          , purchaseTime: obj.upgradeForm.buyNum
          , actualPurchaseTime: month
          , billingModeId: obj.upgradeForm.billingModeId
          , saasGoodsPrice: obj.upgradeForm.sumPrice
          , instanceDomain: ''
          , instanceId: obj.goodsInfo.orderInfo.instanceId
          , packageSpacesId: obj.upgradeForm.packageSpacesId
          //自定义规格需要的参数，如果是扩容，则自定义规格不需要用户数
          // ,userNum: this.ifExpansion == 'true' ? 0 : this.otherSpaces.userNum
          // ,capacity: this.otherSpaces.capacity
          // ,initPrice: this.unitPrice
          // ,productPackageId: this.goodsInfo.productPackages[0].productPackageId
        }).then(res => {
          if (res.code != 200) {
            return obj.$message.error(res.message);
          }
          //初始化socket，打开支付页面
          obj.orderNo = res.data;
          obj.initWebSocket();
          obj.getQrCodeUrl(obj.orderNo);
          this.payDialog = true;
        })

      })
    },
    upgrade1() {
      let obj = this;
      //计算到期时间实际需要延长的月份
      let month = obj.upgradeForm.buyNum;
      if (obj.billingMode == 2) {
        month = month * 12;
      }
      month = month - obj.remainMonth;
      console.log('remainMonth', obj.remainMonth);
      console.log('actualMonth', month);
      if (obj.upgradeForm.sumPrice < 0) {
        //如果小于0，则提示不能购买，要把钱抵消完
        return this.$message.warning("抱歉，上一笔订单的剩余金额将不会遗留，请追加购买数量");
      }
      let title = '是否确认' + obj.title + '？';

      this.$confirm(title, '确认订单信息').then(() => {
        let userInfo = obj.$store.state.userInfo.userInfo;
        api.generatorOtherOrder({
          saasUserID: userInfo.saasUserId
          , saasCompanyId: userInfo.saasCompanyId
          , saasGoodsId: obj.goodsInfo.productInfo.saasProductId
          , orderType: obj.ifExpansion == 'true' ? 4 : 3
          , purchaseTime: obj.upgradeForm.buyNum
          , actualPurchaseTime: month
          , billingModeId: obj.upgradeForm.billingModeId
          , saasGoodsPrice: obj.upgradeForm.sumPrice
          , instanceDomain: ''
          , instanceId: obj.goodsInfo.orderInfo.instanceId
          , packageSpacesId: obj.upgradeForm.packageSpacesId
          //自定义规格需要的参数，如果是扩容，则自定义规格不需要用户数
          // ,userNum: this.ifExpansion == 'true' ? 0 : this.otherSpaces.userNum
          // ,capacity: this.otherSpaces.capacity
          // ,initPrice: this.unitPrice
          // ,productPackageId: this.goodsInfo.productPackages[0].productPackageId
        }).then(res => {
          if (res.code != 200) {
            return obj.$message.error(res.message);
          }
          //初始化socket，打开支付页面
          obj.orderNo = res.data;
          obj.initWebSocket();
          obj.getQrCodeUrl(obj.orderNo);
          this.payDialog = true;
        })

      })
    },
    //获取支付二维码图片
    getQrCodeUrl(orderNo) {
      let obj = this;
      payApi.getQrCodeUrl({"orderNo": orderNo}).then((res) => {
        if (res.code != 200) {
          obj.$alert(res.message + "请在订单中心重新支付").then(() => {
            obj.goOrderInfo();
          })
          return;
        }
        obj.qrCodeImg = res.data;
      })
    },
    // 移动端支付
    getQrCodeUrl1(orderNo) {
      let obj = this;
      payApi.getQrCodeUrl({"orderNo": orderNo, "type": "1"}).then((res) => {
        if (res.code != 200) {
          obj.$alert(res.message + "请在订单中心重新支付").then(() => {
            obj.goOrderInfo();
          })
          return;
        }
        window.location.href = res.data;
      })
    },
    getInstanceInfo(instanceId, ifExpansion) {
      let obj = this;
      api.getInstanceOrderInfo({
        instanceId: instanceId,
        ifExpansion: ifExpansion + ''
      }).then(res => {
        if (res.code != 200) {
          return obj.$message.error(res.message);
        }
        obj.goodsInfo = res.data;

        let productPackage = res.data.productPackageList[0];

        //获取容量和规格的基数与价格
        obj.basic.capacity = productPackage.capacityCardinality;
        obj.basic.capacityPrice = productPackage.capacityCardinalityPrice;
        obj.basic.user = productPackage.userNumCardinality;
        obj.basic.userPrice = productPackage.userNumCardinalityPrice;

        //获取实例当前用户数和容量
        let userNum = obj.goodsInfo.userNum;
        let capacity = obj.goodsInfo.capacity;
        //排除当前规格
        obj.spacesList = productPackage.packageSpaces.filter(spaces => {
          if (spaces.spacesCapacity > capacity || spaces.spacesUserNum > userNum) {
            return true;
          } else {
            return false;
          }
          // return spaces.spacesLevel != obj.goodsInfo.spacesInfo.spacesLevel;
        });


        obj.otherSpaces.userNum = userNum;
        obj.inputUserNum = userNum;

        //自定规格的最小用户数和容量
        obj.basicUser = userNum;
        obj.basicCapacity = capacity;

        //添加自定义规格
        // obj.calculateSpacesPrice();
        // obj.spacesList.push(obj.otherSpaces);

        //获取剩余月份
        let beginTime = obj.$moment(obj.$moment(obj.goodsInfo.orderInfo.orderBeginTime).format("yyyy-MM"));
        let endTime = obj.$moment(obj.$moment(obj.goodsInfo.orderInfo.orderEndTime).format("yyyy-MM"));
        let nowTime = obj.$moment(obj.$moment().format("yyyy-MM"))
        //获取开始到结束时间的间隔月份
        let diffMonth = endTime.diff(beginTime, "month");
        //获取开始到当前时间的间隔月份
        let nowDiffMonth = nowTime.diff(beginTime, "month")
        //总间隔 - 使用时间 = 剩余可使用时间
        obj.remainMonth = diffMonth - nowDiffMonth;
        if (obj.remainMonth < 0) {
          obj.remainMonth = 1;
        }

        //默认选中第一个
        if (obj.spacesList.length == 0) {
          obj.upgradeDisabled = true;
          obj.$message.warning('暂无可用的规格');
        } else {
          obj.upgradeDisabled = false;
          this.chooseSpaces(obj.spacesList[0], 0);
        }

      })
    }
    , chooseSpaces(item, idx) {
      if (item.packageSpecsId == 'other') {
        this.inputUnitChange();
        this.ifOther = true;
      } else {
        this.ifOther = false;
      }
      this.spacesIndex = idx;
      this.upgradeForm.packageSpacesId = item.packageSpecsId

      //获取计费模式，判断展示范围是到月还是到年，默认选中第一个计费模式
      this.modeList = item.billingModes.filter(mode => {
        // if(mode.billingMode != 3) {
        //     return false;
        // }else if(this.remainMonth >= 12 && mode.billingMode == 2) {
        //     //剩余时间大于12个月才显示包年
        //     return true;
        // }else {
        //     return true;
        // }

        if (this.ifExpansion == 'true') {
          //如果当前是扩容，则只要计费模式和上一笔订单一样的
          if (mode.billingMode == this.goodsInfo.billingMode.billingMode) {
            return true;
          }
        } else {
          //如果是升级，直接排除试用的计费模式就好
          return mode.billingMode != 3
        }

      });
      this.modeIndex = 0;
      //默认规格下第一个计费模式
      this.chooseMode(this.modeList[0], 0);

    }
    , chooseMode(item, idx) {
      //切换选中，赋值计费模式id，切换单价，重新计算总价
      this.modeIndex = idx;
      this.upgradeForm.billingModeId = item.billingModeId;
      this.unitPrice = item.purchaseAmount;
      this.billingMode = item.billingMode;

      if (item.billingMode == 1) {
        this.upgradeForm.buyNum = this.remainMonth;
        this.minTime = this.remainMonth;
        this.maxTime = 11;
        this.unit = '月'
      } else {
        //如果是包年，判断有几年
        let num = parseInt(this.remainMonth / 12) + (this.remainMonth % 12 > 0 ? 1 : 0);
        this.upgradeForm.buyNum = num;
        this.minTime = num;
        this.maxTime = 4;
        this.unit = '年'
      }
      this.numberChange()
    },
    inputUnitChange() {
      let capacity = 50;
      if (this.capacityUnit == 'G') {
        this.basicCapacity = 50;
        if (this.goodsInfo.capacity > 50) {
          capacity = this.goodsInfo.capacity + (this.goodsInfo.capacity % 50 > 0 ? 50 : 0);
        }
      } else {
        capacity = 1;
        this.basicCapacity = 1;
        if (this.goodsInfo.capacity > 1024) {
          capacity = parseInt(this.goodsInfo.capacity / 1024) + (this.goodsInfo.capacity % 1024 > 0 ? 1 : 0);
        }
      }

      this.basicCapacity = capacity;
      this.inputCapacity = capacity;

      this.calculateSpacesPrice();
    },
    //计算自定义规格的计费模式的价格
    calculateSpacesPrice() {
      //基数和基数价格就是，比如1用户5块钱，则1就是基数，5就是基数价格
      let otherSpaces = this.otherSpaces;
      otherSpaces.userNum = this.inputUserNum;
      if (this.capacityUnit == 'G') {
        //单位是G则不变
        otherSpaces.capacity = this.inputCapacity;
      } else {
        //单位是T就乘以1024
        otherSpaces.capacity = this.inputCapacity * 1024;
      }
      //包月的用户价格 = (自定义用户量 / 用户基数) * 基数价格
      let monthUserPrice = (otherSpaces.userNum / this.basic.user) * this.basic.userPrice;
      //包月的容量价格 = (自定义容量 / 容量基数) * 基数价格
      let monthCapacityPrice = (otherSpaces.capacity / this.basic.capacity) * this.basic.capacityPrice;

      //计算规格单价
      otherSpaces.billingModes.forEach(item => {
        if (item.billingMode == 1) {
          //包月
          item.purchaseAmount = monthUserPrice + monthCapacityPrice;
        } else {
          //包年
          item.purchaseAmount = (monthUserPrice + monthCapacityPrice) * 12;
        }
        //计算价格
        if (item.billingModeId == this.upgradeForm.billingModeId) {
          this.unitPrice = item.purchaseAmount;
          this.numberChange();
        }
      });

    }
    //todo: 计算最终价格 (单价 * 数量) -  (上一笔订单剩余月数 * 上一笔订单单价)
    , numberChange() {
      this.discountPrice = this.remainMonth * this.goodsInfo.billingMode.purchaseAmount
      let sumPrice = (this.unitPrice * this.upgradeForm.buyNum) - this.discountPrice;
      if (sumPrice === 0) {
        sumPrice = 0.01;
        this.discountPrice -= 0.01;
      }
      this.upgradeForm.sumPrice = sumPrice;
    }
    , goPage(name, ifExpansion) {
      if (name) {
        sessionStorage.setItem("ifExpansion", ifExpansion);
        this.$router.push({path: name});
      }
    }
    , goOrderInfo() {
      this.$router.push({
        path: '/orderList',
        query: {
          instanceId: this.goodsInfo.orderInfo.instanceId
        }
      })
    }
    // 使用websocket建立前后端通讯
    , initWebSocket() {
      if (typeof WebSocket === 'undefined') {
        return null;
      }
      let wsUrl = this.wsUrl + this.orderNo;
      wsUrl = wsUrl.replace("https", "wss").replace("http", "ws");
      this.websocket = new WebSocket(wsUrl);
      this.websocket.onmessage = this.websocketOnMessage
      // this.websocket.onopen = this.websocketOnOpen
      this.websocket.onerror = this.websocketOnError
      this.websocket.onclose = this.websocketOnClose
    },
    websocketOnMessage(res) {
      // 数据接收
      const resData = JSON.parse(res.data);
      if (resData === 200) {
        this.$message.success("支付成功");

        this.payDialog = false;
        this.paySuccessDialog = true;
        this.secondNum = 5;
        let paySuccess = setInterval(() => {
          if (--this.secondNum == 0) {
            clearInterval(paySuccess);
            this.goOrderInfo();
          }
        }, 1000);
      }
    },
    websocketOnOpen() {
      // 连接建立之后执行send发送数据
      // let data = {'orderNo': this.orderNo};
      // console.log(data);
      // this.websocketSend(JSON.stringify(data));
    },
    websocketOnError() {
      // 连接建立失败后重试
      this.initWebSocket();
    },
    websocketOnClose(e) {
      // 断开连接
      console.log("断开连接", e);
    },
    websocketSend(data) {
      console.log(this.websocket);
      this.websocket.send(data);
    },
    close() {
      this.formValidate = {};
      this.purchaseDialog = false;
    }
  }
}
</script>

<style scoped lang="less">
@media only screen and (max-width: 767px) {
  .page {
    background-color: #F5F5F5 !important;
    height: 920px!important;
  }
  .spaces-item {
    border-radius: 14px!important;
    border: 1px solid #3764D7!important;
    color: #3764D7!important;
    width: 110px!important;
    height: 28px!important;
    font-size: 12px!important;
    line-height: 3px !important;
  }
  .item-hover {
    border-radius: 14px!important;
    border: 1px solid #3764D7!important;
    width: 110px!important;
    height: 28px!important;
    font-size: 12px!important;
    line-height: 3px !important;
    color: white !important;
    background-color: #3764D7!important;
  }
  .order-item {
    font-size: 14px!important;
    color: #000000!important;
    margin-bottom: 1rem!important;
    margin: 16px 0px 20px 12px!important;
  }
}
.header-title {
  height: 21px;
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 18px;
  margin: 11px 0px 10px 18px;
}
.down-buy-button {
  border-radius: 36px  36px  36px  36px  ;
  background: #F56E65;
  color: #FFFFFF;
  height: 36px;
}
.down-buy-price {
  height: 22px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  margin: 13px 0px 17px 4px;
}
.down-￥ {
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin: 18px 0px 16px 0px;
}
.down-count {
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
  margin: 17px 0px 17px 23px;
}
.down-mobile {
  position:fixed;
  left:0px;
  bottom:0px;
  z-index:999;
}
.mobile-product-tile1 {
  //width: 76px;
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
  margin: 12px 0px 0px 16px;
}

.mobile-product-tile {
  //width: 76px;
  height: 21px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin: 12px 0px 0px 16px;
}

.mobile-product-value {
  width: 264px;
  height: 21px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  text-align: right;
  margin: 12px 16px 0px 0px;
}
.mobile-goods-spe {
  width: 95.66667%;
  margin: 8px 8px 0px 8px;
  //height: 103px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
}
.mobile-goods-describe {
  width: 95.66667%;
  height: 128px;
  margin: 8px 8px 0px 8px;
  //height: 103px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
}
.mobile-btn {
  font-weight: 400;
  font-size: 12px;
  width: 72px;
  height: 28px;
  line-height: 5px;
  background: #FFFFFF;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  border: 1px solid #3764D7;
  color: #3764D7;
}

.mobile-group-but {
  margin-top: 27px;
  margin-left: 12px;
}

.mobile-product-name {
  width: 132px;
  height: 22px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  margin: 13px 0px 0px 14px;
}

.mobile-product_img {
  margin: 12px 0px 12px 16px;
  width: 78px;
  height: 78px;
}

.mobile-instance {
  width: 95.66667%;
  margin: 8px 8px 0px 8px;
  //height: 103px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
}

* {
  font-size: 14px;
}

.upgrade {
  max-width: 1200px;
  width: 100%;
  margin: 2rem auto;
}

.upgrade-title {
  font-size: 32px;
  font-weight: 500;
  color: #3764D7;
  line-height: 45px;
  margin: 2rem 0rem;
}

.upgrade-content {
  display: flex;
}

.content-left {
  width: 16rem;
  height: 100%;
  padding: 0rem 1rem 1rem;
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #C3C3C3;
  margin-right: 3rem;
}

.goods-img {
  width: 15rem;
  height: 12rem;
}

.goods-title {
  font-size: 18px;
  font-weight: 500;
  color: #3764D7;
  line-height: 25px;
}

.button-list {
  margin-top: 1rem;
}

.button-list .goods-button:first-child {
  margin-left: 0;
}

.goods-button {
  width: 56px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #B4C4ED;
  background-color: white;
  color: #3764D7;
  margin-left: 2rem;
  cursor: pointer;
}

.goods-button:hover {
  color: white;
  background-color: #3764D7;
}

.content-right {
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #C3C3C3;
  padding: 1rem;
  height: 100%;
}

.info-list {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.info-item {
  flex-grow: 1;
  flex-basis: 40%;
  margin-bottom: 1rem;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
}

.order-item {
  font-size: 14px;
  color: #000000;
  margin-bottom: 1rem;
}

.spaces-item {
  border-radius: 4px;
  border: 1px solid #3764D7;
  color: #3764D7;
}

/*.spaces-item:hover {*/
/*    background-color: #3764D7;*/
/*    color: white;*/
/*}*/
.item-hover {
  color: white;
  background-color: #3764D7;
}

.order-price {
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  line-height: 33px;
}

.price-discount {
  font-size: 14px;
  font-weight: 400;
  color: #E30000;
  line-height: 20px;
}

.upgrade-button {
  width: 208px;
  height: 34px;
  background: #F56E65;
  border-radius: 10px;
  line-height: 34px;
  border: 0px;
  color: white;
  cursor: pointer;
}

.other-spaces {
  margin-top: 10px;
}

.product-pay {

  .qr-code {
    margin-top: 23px;
      //margin-left: 370px;
      text-align: center;

    img {
      width: 160px;
      height: 160px;
    }
  }

  .price {
    text-align: center;
    margin-top: 16px;
    color: #E30000;
    line-height: 20px;

    .symbol {
      font-size: 14px;
      font-weight: 200;
    }

    .amount {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .pay-success-dialog {
    /deep/ .el-dialog__body {
      padding: 0 20px 30px 20px;
      height: 80px !important;
    }
  }

  .pay-dialog {

    /deep/ .el-dialog__body {
      padding: 0 20px 30px 20px;
      height: 309px !important;
    }

    .pay-type {
      height: 50px;
      border-top: 1px solid #cbcbcb;
      padding: 16px 0 16px 0px;
      border-bottom: 1px solid #cbcbcb;

      .active {
        border-bottom: 3px solid #3764D7;
        padding-bottom: 8px;
      }

      .pay-ali {
          display: flex;
          justify-content: center;
      }

      .pay-wechat:hover, .pay-ali:hover {
        cursor: pointer;
      }

      .pay-wechat, .pay-ali {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        vertical-align: top;

        .el-icon-wechat {
          background: url("../../img/goods/weixinzhifu.svg") no-repeat top center;
          width: 22px;
          height: 19px;

        }

        .el-icon-ali {
          background: url("../../img/goods/zhifubao.svg") no-repeat top center;
          width: 20px;
          height: 20px;
        }

        .pay-icon {
          float: unset;
          display: inline-block;
        }

        .pay-title {
          float: unset;
          display: inline-block;
          vertical-align: top;
          margin-left: 9px;
        }
      }
    }
  }
}


</style>
